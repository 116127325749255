<style scoped>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: letter portrait;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
        }

        .page-break {
            page-break-before: always;
            counter-reset: page;
        }
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td, th.bordered {
        border: 1px solid #000000;
        text-align: left;
        padding: 8px;
    }

    th.no-border {
        border: none;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    .info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }

    #page-counter {
        display: table-footer-group;
        }
        
    #page-counter:after {
        counter-increment: page;
        content: ' ' counter(page) ' of ' counter(page);
    }
</style>

<template>
    <div>
        <div class="page-break" :key="idx" v-for="(data, idx) in datas">
            <table class="" style="width:100%">
                <thead>
                    <tr class="">
                        <th colspan="7">
                            <div class="title">
                                <p>PICKING LIST</p>
                                <p>PT. SINARMAS DISTRIBUSI NUSANTARA</p>
                            </div>
                        </th>
                    </tr>
                    <tr class="">
                        <th class=" py-0 w-32">Cabang</th>
                        <th class=" py-0 w-1">:</th>
                        <th class=" py-0 w-32">{{ data.Warehouse }}</th>
                        <th class=" py-0 w-32"></th>
                        <th class=" py-0 w-5">Print</th>
                        <th class=" py-0 w-1" colspan="2">: {{ data.PrintCounter }}</th>
                    </tr>
                    <tr>
                        <th class=" py-0 w-32">Shipment Plan</th>
                        <th class=" py-0 w-1">:</th>
                        <th class=" py-0 w-32">{{ data.ShipmentNumber }}</th>
                        <th class=" py-0 w-32"></th>
                        <th class=" py-0 w-5">Print date</th>
                        <th class=" py-0 w-1" colspan="2">: {{ dateNow }}</th>
                    </tr>
                    <tr>
                        <th class=" py-0 w-32">Tanggal Shipment</th>
                        <th class=" py-0 w-1">:</th>
                        <th class=" py-0 w-32">{{ formatDate(data.Date) }}</th>
                        <th class=" py-0 w-32"></th>
                        <th class=" py-0 w-5">Print time</th>
                        <th class=" py-0 w-1" colspan="2">: {{ timeNow }}</th>
                    </tr>
                    <tr>
                        <th class=" py-0 w-32">Driver</th>
                        <th class=" py-0 w-1">:</th>
                        <th class=" py-0 w-32">{{ data.DriverName }}</th>
                        <th class=" py-0 w-32"></th>
                        <th class=" py-0 w-5">Page</th>
                        <th class=" py-0 w-1" colspan="2"><div id="page-counter">:</div></th>
                    </tr>
                    <tr>
                        <th class=" py-0 w-32">Vehicle Number</th>
                        <th class=" py-0 w-1">:</th>
                        <th class=" py-0 w-32">{{ data.VehicleNumber }}</th>
                        <th class=" py-0 w-32"></th>
                        <th class=" py-0 w-5"></th>
                        <th class=" py-0 w-1"></th>
                        <th class=" py-0 w-5"></th>
                    </tr>
                <!-- </thead>
                <thead> -->
                    <tr>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">SKU Code</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;" colspan="2">SKU Name</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">BATCH</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">ED</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">QTY</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">UOM</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(tr, idxtr) in data.table" >
                        <template v-for="(tr2, idxtr2) in tr.data">
                            <tr :key="`${idxtr}-${idxtr2}-${idxtr3}`" v-for="(tr3, idxtr3) in tr2.data">
                                <td class="py-1">{{ tr3.sku_code }}</td>
                                <td class="py-1" colspan="2">{{ tr3.sku_name }}</td>
                                <td class="py-1">{{ tr3.batch }}</td>
                                <td class="py-1">{{ formatDate(tr3.expired_date) }}</td>
                                <td class="py-1">{{ tr3.qty }}</td>
                                <td class="py-1">{{ tr3.uom }}</td>
                            </tr>
                        </template>
                        <template v-for="(tr2, idxtr2) in tr.data">
                            <tr :key="`${idxtr}-${idxtr2}`">
                                <td class="py-1" v-if="idxtr2 == 0" colspan="5" :rowspan="tr.data.length" style="vertical-align: middle; text-align:center;">Total</td>
                                <td class="py-1">{{ tr2.total }}</td>
                                <td class="py-1">{{ tr2.uom }}</td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
            <br/>
            <table class="remove_border">
                <tr>
                    <th style="text-align:center;">Dibuat oleh</th>
                    <th style="text-align:center;">Disiapkan oleh</th>
                    <th style="text-align:center;">Diterima oleh</th>
                </tr>
                <tr>
                    <th style="height: 50px;"></th>
                    <th style="height: 50px;"></th>
                    <th style="height: 50px;"></th>
                </tr>
                <tr>
                    <th style="text-align:center;">Admin Logistic</th>
                    <th style="text-align:center;">Picker</th>
                    <th style="text-align:center;">Checker</th>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
    name: "PrintPdf",
    // props: {
    //     handover_id: Number
    // },
    data() {
        return {
            id: null,
            handoverIds: null,
            // table: [],
            sto_plan_code: null,
            picking_code: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            // logoSDN: logoSDN,
            showInfo: false,
            // data: {},
            datas: [],
            dateNow: "",
            timeNow: "",
        }
    },
    mounted() {
        // console.log("opener", window.opener.handoverIds)
        var now = moment()
        now.add(1, 'days')

        this.reqDeliveryDate = now.toDate()
        this.eta = now.toDate()

        this.handoverIds = window.opener.handoverIds
        if (this.handoverIds.length) {
            // this.getData()
            this.print();
        }
    },
    methods: {
        async print() {
            const tempTitle = document.title
            await this.getData()
            await window.print()
            document.title = tempTitle
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get(`/api/wms/v1/simple-outbound-planner/picking-data-by-handover`, {params:{handover_ids: this.handoverIds, is_print:1}});
            if (resp.code == 200) {
                this.datas = resp.data.map(data => {
                    const table = []
                    const mapSkuBEdUnitGroup = {}
                    data.PickingItem.forEach(pi => {
                        pi.PickingItemStock.forEach(pis => {
                            const key = `${pis.SkuCode}*${pis.Batch}*${pis.ExpiredDate}*${pis.Unit}`
                            let su = mapSkuBEdUnitGroup[key]
                            if (!su) su = {
                                sku_code: pis.SkuCode,
                                sku_name: pis.ItemName,
                                batch: pis.Batch,
                                ed: pis.ExpiredDate,
                                qty: pis.Amount,
                                uom: pis.Unit,
                                level: pis.UnitLevel
                            }
                            else su.qty += pis.Amount
                            mapSkuBEdUnitGroup[key] = su
                        })
                    })
                    const mapSkuUnitBEd = {}
                    for (const msu in mapSkuBEdUnitGroup) {
                        const splitKey = msu.split("*")
                        const key = `${splitKey[0]}*${splitKey[splitKey.length-1]}`
                        let su = mapSkuUnitBEd[key]
                        if (!su) su = {data: [], uom: mapSkuBEdUnitGroup[msu].uom, level: mapSkuBEdUnitGroup[msu].level, total: 0}
                        su.data.push(mapSkuBEdUnitGroup[msu])
                        su.total += mapSkuBEdUnitGroup[msu].qty
                        mapSkuUnitBEd[key] = su
                    }
                    const mapSkuUnit = {}
                    for (const msu in mapSkuUnitBEd) {
                        const splitKey = msu.split("*")
                        const key = splitKey[0]
                        let su = mapSkuUnit[key]
                        if (!su) su = {data: [], uom: mapSkuUnitBEd[msu].uom, level: mapSkuUnitBEd[msu].level, total: 0}
                        su.data.push(mapSkuUnitBEd[msu])
                        su.total += mapSkuUnitBEd[msu].total
                        mapSkuUnit[key] = su
                    }
                    for (const msu in mapSkuUnit) {
                        mapSkuUnit[msu].data = mapSkuUnit[msu].data.sort((a, b) => b.level - a.level)
                        table.push(mapSkuUnit[msu])
                    }
                    data.table = table
                    return data
                })
                document.title = this.datas.reduce((prev, curr) => `${prev}-${curr.ShipmentNumber}`, "Picking_List")
                const now = new Date()
                this.dateNow = this.formatDate(now.setHours(now.getHours()+7))
                this.timeNow = this.formatTime(now)
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                // momentDate.add(1, 'days');              // Tambahkan 1 hari
                return momentDate.format('DD/MM/YYYY');
            };
        },
        formatTime: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('hh:mm:ss');
            };
        },
    },
}
</script>
